import defaultSettings from '../settings.json';
import { IRoute } from '@/routes';

export interface GlobalState {
  settings?: typeof defaultSettings;
  userInfo?: {
    nickName?: string;
    avatar?: string;
    admin?: boolean;
  };
  userLoading?: boolean;
    // 当前用户的权限字符列表
    permissions: string[];
  // 当前用户的路由列表
  routers: IRoute[];
}

const initialState: GlobalState = {
  settings: defaultSettings,
  userInfo: {},
  permissions: [],
  routers: [],
};

export default function store(state = initialState, action) {
  switch (action.type) {
    case 'update-settings': {
      const { settings } = action.payload;
      return {
        ...state,
        settings,
      };
    }
    case 'update-userInfo': {
      const { userInfo = initialState.userInfo, userLoading, permissions = [], routers = []} = action.payload;
      return {
        ...state,
        userLoading,
        userInfo,
        permissions,
        routers,
      };
    }
    default:
      return state;
  }
}
