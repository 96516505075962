import { request, METHOD } from '../utils/request'

/**
 * 登录
 */
export async function onLogin(params) {
  const { data } = await request('/auth/login', METHOD.POST, params)
  return data;
}

/**
 * 获取账号信息
 */
export async function getUserInfo() {
  const { data } = await request('/auth/current', METHOD.GET)
  return data;
}

/**
 * 获取路由列表
 */
export async function getRouters() {
  const { data } = await request('/auth/routers', METHOD.GET)
  return data;
}
