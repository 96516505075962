import _ from 'lodash'


export default function checkLogin() {
  let token = localStorage.getItem('Authorization');
  if (!_.isEmpty(token) && "null" !== token) {
    return true
  }
  return false;
}
