import _ from 'lodash'
import { Message } from '@arco-design/web-react';
import UserCacheUtil from './AppCacheUtil';

let tokenFailure = _.throttle(async (options) => {
  const token = localStorage.getItem('Authorization')
  if (!_.isEmpty(token) && "null" !== token) {
    Message.error('登录失效，请重新登录！')
    UserCacheUtil.loginOut(options)
  }
}, 2000)
// 401拦截
const respCommon = {
  /**
   * 响应数据之前做点什么
   * @param response 响应对象
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {*}
   */
  onFulfilled(response, options) {
    if (response.data.code == '-1' || response.data.code == '-3') {
      //请求成功但是 code返回-1 代表token失效
      tokenFailure(options)
    } else if (response.data.code != '200') {
      Message.error(response.data.msg)
      return Promise.reject(response.data.code)
    }
    return response.data
  },
  /**
   * 响应出错时执行
   * @param error 错误对象
   * @returns {Promise<never>}
   */
  onRejected(error, options) {
    const { response ,message} = error
    if (response && response.status !== 200) {
      Message.error('请求失败，请稍后再试')
    }else {
      Message.error(message)
    }
    return Promise.reject(error)
  }
}

const reqCommon = {
  /**
   * 发送请求之前做些什么
   * @param config axios config
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {*}
   */
  // eslint-disable-next-line no-unused-vars
  onFulfilled(config, options) {
    return config
  },
  /**
   * 请求出错时做点什么
   * @param error 错误对象
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {Promise<never>}
   */
  onRejected(error, options) {
    Message.error(error.message)
    return Promise.reject(error)
  }
}

export default {
  request: [reqCommon], // 请求拦截
  response: [respCommon] // 响应拦截
}
