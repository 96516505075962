import React from 'react';
import { Carousel } from '@arco-design/web-react';
import useLocale from '@/utils/useLocale';
import locale from './locale';
import styles from './style/index.module.less';

export default function LoginBanner() {
  const t = useLocale(locale);
  const data = [
    {
      slogan: '智能引擎，通道互联',
      subSlogan: '支付新未来',
      image: require('../../assets/banner.png'),
    },
  ];
  return (
    <Carousel className={styles.carousel} animation="fade">
      {data.map((item, index) => (
        <div key={`${index}`}>
          <div className={styles['carousel-item']}>
            <div className={styles['carousel-title']}>{item.slogan}</div>
            <div className={styles['carousel-sub-title']}>{item.subSlogan}</div>
            {item.image ? (
              <img
                alt="banner-image"
                className={styles['carousel-image']}
                src={item.image}
              />
            ) : null}
          </div>
        </div>
      ))}
    </Carousel>
  );
}
