import md5 from 'js-md5';

/**
 * 对密码进行加密
 * @param password 密码
 * @returns 
 */
export const encryptPassword = (password): string => {
  const fistEncrypt = md5(password);
  const salt = 'posp'; // 加点字符串杜绝网络上泄露的MD5表反查
  return md5(fistEncrypt + salt);
}