import _ from 'lodash';

// ：：：：：：：：：：：：：：：：：：：：
//
//  注意：：：：请通过后台界面动态添加路由，这个文件一般不用动
//
// ：：：：：：：：：：：：：：：：：：：：

export type IRoute =  {
  name: string;
  key: string;
  // 菜单图标，如：IconThunderbolt
  icon?: string;
  // 当前页是否展示面包屑
  breadcrumb?: boolean;
  // 当前路由是否渲染菜单项，为 true 的话不会在菜单中显示，但可通过路由地址访问。
  ignore?: boolean;
  children?: IRoute[];
};

// 侧边栏公共路由（放在动态路由前面）
const commonRouters: IRoute[] = [
  // {
  //   name: '目录',
  //   key: 'test',
  //   icon: 'IconThunderbolt',
  //   children: [
  //     {
  //       name: '菜单1',
  //       key: 'test/menu1',
  //       icon: 'IconThunderbolt',
  //     },
  //     {
  //       name: '菜单2',
  //       key: 'test/menu2',
  //     }
  //   ]
  // },
];

const generateRoute = (userRouters: IRoute[]): { routers: IRoute[], defaultRoute: string } => {
  const handleRoute = (routes: IRoute[], arr = []): IRoute[] => {
    if (!routes.length) return [];
    for (const route of routes) {
      if(!route.key) route.key = _.uniqueId();
      if (route.children && route.children.length) {
        const newRoute = { ...route, children: [] };
        handleRoute(route.children, newRoute.children);
        if (newRoute.children.length) {
          arr.push(newRoute);
        }
      } else {
        arr.push({ ...route });
      }
    }

    return arr;
  };
  // 优化动态路由数据
  const dynamicRouters = handleRoute(userRouters);
  // 整合 公共路由 与 动态路由
  const routers = [...commonRouters, ...dynamicRouters];

  // 第一个默认展示的路由
  let defaultRoute = '';
  const first = routers[0];
  if (first) {
    defaultRoute = first?.children?.[0]?.key || first.key;
  }

  return {
    routers, 
    defaultRoute
  }
};

export default generateRoute;