import React from 'react';
import * as Icons from '@arco-design/web-react/icon';

export const ICONS = {
  'IconThunderbolt': Icons.IconThunderbolt,
  'IconReply': Icons.IconReply,
  'IconTwitterCircleFill': Icons.IconTwitterCircleFill,
  'IconEnglishFill': Icons.IconEnglishFill,
  'IconPalette': Icons.IconPalette,
  'IconVoice': Icons.IconVoice,
  'IconSort': Icons.IconSort,
  'IconObliqueLine': Icons.IconObliqueLine,
  'IconCloseCircleFill': Icons.IconCloseCircleFill,
  'IconSkipNextFill': Icons.IconSkipNextFill,
  'IconShake': Icons.IconShake,
  'IconPlus': Icons.IconPlus,
  'IconDownload': Icons.IconDownload,
  'IconTool': Icons.IconTool,
  'IconSound': Icons.IconSound,
  'IconFolderAdd': Icons.IconFolderAdd,
  'IconSwap': Icons.IconSwap,
  'IconLanguage': Icons.IconLanguage,
  'IconCodeSquare': Icons.IconCodeSquare,
  'IconSun': Icons.IconSun,
  'IconMuteFill': Icons.IconMuteFill,
  'IconSafe': Icons.IconSafe,
  'IconSend': Icons.IconSend,
  'IconCloud': Icons.IconCloud,
  'IconImport': Icons.IconImport,
  'IconQrcode': Icons.IconQrcode,
  'IconThumbUpFill': Icons.IconThumbUpFill,
  'IconArrowUp': Icons.IconArrowUp,
  'IconRotateLeft': Icons.IconRotateLeft,
  'IconQuestion': Icons.IconQuestion,
  'IconExport': Icons.IconExport,
  'IconPlusCircle': Icons.IconPlusCircle,
  'IconMosaic': Icons.IconMosaic,
  'IconExclamationCircleFill': Icons.IconExclamationCircleFill,
  'IconExclamationPolygonFill': Icons.IconExclamationPolygonFill,
  'IconMinusCircleFill': Icons.IconMinusCircleFill,
  'IconUnderline': Icons.IconUnderline,
  'IconBackward': Icons.IconBackward,
  'IconItalic': Icons.IconItalic,
  'IconWechat': Icons.IconWechat,
  'IconToTop': Icons.IconToTop,
  'IconDice': Icons.IconDice,
  'IconDriveFile': Icons.IconDriveFile,
  'IconMobile': Icons.IconMobile,
  'IconToRight': Icons.IconToRight,
  'IconEye': Icons.IconEye,
  'IconDoubleUp': Icons.IconDoubleUp,
  'IconToBottom': Icons.IconToBottom,
  'IconFileAudio': Icons.IconFileAudio,
  'IconEyeInvisible': Icons.IconEyeInvisible,
  'IconMenuUnfold': Icons.IconMenuUnfold,
  'IconGoogle': Icons.IconGoogle,
  'IconMenu': Icons.IconMenu,
  'IconWeiboCircleFill': Icons.IconWeiboCircleFill,
  'IconStrikethrough': Icons.IconStrikethrough,
  'IconUnorderedList': Icons.IconUnorderedList,
  'IconCodepen': Icons.IconCodepen,
  'IconMore': Icons.IconMore,
  'IconInfoCircleFill': Icons.IconInfoCircleFill,
  'IconAlignCenter': Icons.IconAlignCenter,
  'IconMoonFill': Icons.IconMoonFill,
  'IconPauseCircleFill': Icons.IconPauseCircleFill,
  'IconRotateRight': Icons.IconRotateRight,
  'IconAttachment': Icons.IconAttachment,
  'IconPoweroff': Icons.IconPoweroff,
  'IconFaceMehFill': Icons.IconFaceMehFill,
  'IconCalendar': Icons.IconCalendar,
  'IconSkin': Icons.IconSkin,
  'IconGitlab': Icons.IconGitlab,
  'IconHeart': Icons.IconHeart,
  'IconSave': Icons.IconSave,
  'IconFile': Icons.IconFile,
  'IconFormula': Icons.IconFormula,
  'IconThumbDownFill': Icons.IconThumbDownFill,
  'IconNotificationClose': Icons.IconNotificationClose,
  'IconMinusCircle': Icons.IconMinusCircle,
  'IconQqZone': Icons.IconQqZone,
  'IconCodeBlock': Icons.IconCodeBlock,
  'IconWoman': Icons.IconWoman,
  'IconCopy': Icons.IconCopy,
  'IconCommon': Icons.IconCommon,
  'IconPublic': Icons.IconPublic,
  'IconLiveBroadcast': Icons.IconLiveBroadcast,
  'IconSubscribed': Icons.IconSubscribed,
  'IconDragDotVertical': Icons.IconDragDotVertical,
  'IconHighlight': Icons.IconHighlight,
  'IconExclamationCircle': Icons.IconExclamationCircle,
  'IconArrowFall': Icons.IconArrowFall,
  'IconMenuFold': Icons.IconMenuFold,
  'IconRobot': Icons.IconRobot,
  'IconThumbDown': Icons.IconThumbDown,
  'IconShrink': Icons.IconShrink,
  'IconZoomIn': Icons.IconZoomIn,
  'IconWifi': Icons.IconWifi,
  'IconAt': Icons.IconAt,
  'IconBulb': Icons.IconBulb,
  'IconMusic': Icons.IconMusic,
  'IconAlignLeft': Icons.IconAlignLeft,
  'IconSelectAll': Icons.IconSelectAll,
  'IconScissor': Icons.IconScissor,
  'IconDoubleLeft': Icons.IconDoubleLeft,
  'IconSortDescending': Icons.IconSortDescending,
  'IconQq': Icons.IconQq,
  'IconQuestionCircle': Icons.IconQuestionCircle,
  'IconPlayCircleFill': Icons.IconPlayCircleFill,
  'IconTag': Icons.IconTag,
  'IconFacebook': Icons.IconFacebook,
  'IconFullscreenExit': Icons.IconFullscreenExit,
  'IconImageClose': Icons.IconImageClose,
  'IconQuote': Icons.IconQuote,
  'IconFilePdf': Icons.IconFilePdf,
  'IconAlignRight': Icons.IconAlignRight,
  'IconGift': Icons.IconGift,
  'IconCustomerService': Icons.IconCustomerService,
  'IconFontColors': Icons.IconFontColors,
  'IconRecordStop': Icons.IconRecordStop,
  'IconClockCircle': Icons.IconClockCircle,
  'IconLock': Icons.IconLock,
  'IconHome': Icons.IconHome,
  'IconUndo': Icons.IconUndo,
  'IconDownCircle': Icons.IconDownCircle,
  'IconPaste': Icons.IconPaste,
  'IconSortAscending': Icons.IconSortAscending,
  'IconCopyright': Icons.IconCopyright,
  'IconRight': Icons.IconRight,
  'IconMinus': Icons.IconMinus,
  'IconBrush': Icons.IconBrush,
  'IconSkipPrevious': Icons.IconSkipPrevious,
  'IconFolder': Icons.IconFolder,
  'IconPlayCircle': Icons.IconPlayCircle,
  'IconTrophy': Icons.IconTrophy,
  'IconMoon': Icons.IconMoon,
  'IconFaceSmileFill': Icons.IconFaceSmileFill,
  'IconMan': Icons.IconMan,
  'IconRecord': Icons.IconRecord,
  'IconCaretUp': Icons.IconCaretUp,
  'IconCheck': Icons.IconCheck,
  'IconPlayArrowFill': Icons.IconPlayArrowFill,
  'IconBook': Icons.IconBook,
  'IconAlipayCircle': Icons.IconAlipayCircle,
  'IconCaretLeft': Icons.IconCaretLeft,
  'IconUserGroup': Icons.IconUserGroup,
  'IconLineHeight': Icons.IconLineHeight,
  'IconMessage': Icons.IconMessage,
  'IconFaceFrownFill': Icons.IconFaceFrownFill,
  'IconLoop': Icons.IconLoop,
  'IconSync': Icons.IconSync,
  'IconCalendarClock': Icons.IconCalendarClock,
  'IconForward': Icons.IconForward,
  'IconNotification': Icons.IconNotification,
  'IconDown': Icons.IconDown,
  'IconList': Icons.IconList,
  'IconSubscribeAdd': Icons.IconSubscribeAdd,
  'IconFilter': Icons.IconFilter,
  'IconEmpty': Icons.IconEmpty,
  'IconExpand': Icons.IconExpand,
  'IconZoomOut': Icons.IconZoomOut,
  'IconArrowLeft': Icons.IconArrowLeft,
  'IconEar': Icons.IconEar,
  'IconImage': Icons.IconImage,
  'IconLayout': Icons.IconLayout,
  'IconMindMapping': Icons.IconMindMapping,
  'IconPen': Icons.IconPen,
  'IconUpCircle': Icons.IconUpCircle,
  'IconUp': Icons.IconUp,
  'IconNav': Icons.IconNav,
  'IconFileImage': Icons.IconFileImage,
  'IconShareExternal': Icons.IconShareExternal,
  'IconRightCircle': Icons.IconRightCircle,
  'IconCloudDownload': Icons.IconCloudDownload,
  'IconStarFill': Icons.IconStarFill,
  'IconPlayArrow': Icons.IconPlayArrow,
  'IconLoading': Icons.IconLoading,
  'IconHistory': Icons.IconHistory,
  'IconBytedanceColor': Icons.IconBytedanceColor,
  'IconInfo': Icons.IconInfo,
  'IconArrowRight': Icons.IconArrowRight,
  'IconTwitter': Icons.IconTwitter,
  'IconDashboard': Icons.IconDashboard,
  'IconStamp': Icons.IconStamp,
  'IconArchive': Icons.IconArchive,
  'IconPushpin': Icons.IconPushpin,
  'IconRobotAdd': Icons.IconRobotAdd,
  'IconCaretDown': Icons.IconCaretDown,
  'IconQqCircleFill': Icons.IconQqCircleFill,
  'IconSkipPreviousFill': Icons.IconSkipPreviousFill,
  'IconPlusCircleFill': Icons.IconPlusCircleFill,
  'IconTranslate': Icons.IconTranslate,
  'IconDesktop': Icons.IconDesktop,
  'IconStorage': Icons.IconStorage,
  'IconCheckCircle': Icons.IconCheckCircle,
  'IconStar': Icons.IconStar,
  'IconPauseCircle': Icons.IconPauseCircle,
  'IconSubscribe': Icons.IconSubscribe,
  'IconCheckSquare': Icons.IconCheckSquare,
  'IconBgColors': Icons.IconBgColors,
  'IconFaceBookCircleFill': Icons.IconFaceBookCircleFill,
  'IconDelete': Icons.IconDelete,
  'IconBold': Icons.IconBold,
  'IconPause': Icons.IconPause,
  'IconSettings': Icons.IconSettings,
  'IconScan': Icons.IconScan,
  'IconLeftCircle': Icons.IconLeftCircle,
  'IconH7': Icons.IconH7,
  'IconChineseFill': Icons.IconChineseFill,
  'IconHeartFill': Icons.IconHeartFill,
  'IconInteraction': Icons.IconInteraction,
  'IconFolderDelete': Icons.IconFolderDelete,
  'IconUnlock': Icons.IconUnlock,
  'IconH1': Icons.IconH1,
  'IconH2': Icons.IconH2,
  'IconH3': Icons.IconH3,
  'IconH4': Icons.IconH4,
  'IconH5': Icons.IconH5,
  'IconApps': Icons.IconApps,
  'IconH6': Icons.IconH6,
  'IconFacebookSquareFill': Icons.IconFacebookSquareFill,
  'IconUserAdd': Icons.IconUserAdd,
  'IconShareInternal': Icons.IconShareInternal,
  'IconExperiment': Icons.IconExperiment,
  'IconFileVideo': Icons.IconFileVideo,
  'IconOriginalSize': Icons.IconOriginalSize,
  'IconMute': Icons.IconMute,
  'IconThumbUp': Icons.IconThumbUp,
  'IconOrderedList': Icons.IconOrderedList,
  'IconTags': Icons.IconTags,
  'IconLarkColor': Icons.IconLarkColor,
  'IconSunFill': Icons.IconSunFill,
  'IconToLeft': Icons.IconToLeft,
  'IconEdit': Icons.IconEdit,
  'IconVideoCamera': Icons.IconVideoCamera,
  'IconFindReplace': Icons.IconFindReplace,
  'IconBranch': Icons.IconBranch,
  'IconDragArrow': Icons.IconDragArrow,
  'IconXiguaColor': Icons.IconXiguaColor,
  'IconLeft': Icons.IconLeft,
  'IconLaunch': Icons.IconLaunch,
  'IconUpload': Icons.IconUpload,
  'IconCommand': Icons.IconCommand,
  'IconIdcard': Icons.IconIdcard,
  'IconFullscreen': Icons.IconFullscreen,
  'IconEmail': Icons.IconEmail,
  'IconTiktokColor': Icons.IconTiktokColor,
  'IconCheckCircleFill': Icons.IconCheckCircleFill,
  'IconEraser': Icons.IconEraser,
  'IconSoundFill': Icons.IconSoundFill,
  'IconFire': Icons.IconFire,
  'IconArrowDown': Icons.IconArrowDown,
  'IconPrinter': Icons.IconPrinter,
  'IconGithub': Icons.IconGithub,
  'IconQuestionCircleFill': Icons.IconQuestionCircleFill,
  'IconCloseCircle': Icons.IconCloseCircle,
  'IconGoogleCircleFill': Icons.IconGoogleCircleFill,
  'IconWechatpay': Icons.IconWechatpay,
  'IconCamera': Icons.IconCamera,
  'IconDoubleRight': Icons.IconDoubleRight,
  'IconSchedule': Icons.IconSchedule,
  'IconArrowRise': Icons.IconArrowRise,
  'IconCaretRight': Icons.IconCaretRight,
  'IconCompass': Icons.IconCompass,
  'IconWeibo': Icons.IconWeibo,
  'IconExclamation': Icons.IconExclamation,
  'IconSkipNext': Icons.IconSkipNext,
  'IconUser': Icons.IconUser,
  'IconClose': Icons.IconClose,
  'IconShareAlt': Icons.IconShareAlt,
  'IconDragDot': Icons.IconDragDot,
  'IconStop': Icons.IconStop,
  'IconMessageBanned': Icons.IconMessageBanned,
  'IconPenFill': Icons.IconPenFill,
  'IconDoubleDown': Icons.IconDoubleDown,
  'IconMoreVertical': Icons.IconMoreVertical,
  'IconLink': Icons.IconLink,
  'IconCodeSandbox': Icons.IconCodeSandbox,
  'IconRedo': Icons.IconRedo,
  'IconPhone': Icons.IconPhone,
  'IconLocation': Icons.IconLocation,
  'IconCode': Icons.IconCode,
  'IconRefresh': Icons.IconRefresh,
  'IconSearch': Icons.IconSearch,
  'IconInfoCircle': Icons.IconInfoCircle,
  'IconBug': Icons.IconBug,  
};

const Icon = ({
  type,
  style = {},
}: {
  type: string;
  style?: object;
}) => {
  const IconDom = ICONS[type];
  return IconDom ? React.createElement(IconDom, { style }) : null;
};

export default Icon;
