
export default class UserCacheUtil {

  /*******************************************业务分割线****************************************************/

  static loginOut({store}) {
    localStorage.setItem('Authorization', null);
    store.dispatch({
      type: 'update-userInfo',
      payload: { userInfo: null, userLoading: false },
    });
    window.location.href = '/login';
  }
}
